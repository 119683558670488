import cloneDeep from 'clone-deep'

export const removeObjectBasedOnKey=(items, comparaKey, compareValue)=>{
    return items.filter(item=>{
        return item[comparaKey] !==compareValue
    })
}


//does not make a deep copy and changes the order of items --> not good
// export const updateOrAddObjectBasedOnKey=(items, comparaKey, updatedObject)=>{
//     let newItems=removeObjectBasedOnKey(items, comparaKey, updatedObject[comparaKey])
//     newItems.push(updatedObject);
//     return newItems;
// }

export const updateOrAddObjectBasedOnKey=(items, comparaKey, updatedObject)=>{

    let newItems=cloneDeep(items)
    
    let index=newItems.findIndex(item=>{
        return item[comparaKey]===updatedObject[comparaKey];
    })

    if(index===-1){
        newItems.push(updatedObject)
    }else{
        newItems[index]=updatedObject;
    }

    return newItems;
}