import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import { AS_USER } from "../../others/GlobalConst";
import { userChangePassword } from "../../actions/usersActions";

//components
import ResetPassword from "../common/ResetPassword";
import MessageHandler from "../common/MessageHandler";
import LogBtnUser from "./LogBtnUser";

//actions
import {
  setNavbarHeight,
  setRef,
  setWindowResize,
} from "../../actions/pageAction";
import { logout } from "../../actions/authActions";

//materialUI
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { Toolbar } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    //flexGrow:1,
    width: "100%",
    //zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    float: "left",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginRight: theme.spacing(4),
  },
  title: {
    textDecoration: "none",
    color: "#7EDAFF",
  },
  version: {
    color: "#7EDAFF",
    fontSize: 12,
    paddingBottom: 6,
  },
  linksGroup: {
    flexGrow: 1,
    position: "relative",
    float: "right",
    color: "#000",
    display: "flex",
    flexWrap: "nowarp",
  },
  logGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {},
});

const initState = {
  passwordDialogOpen: false,
  activeUserId: null,
  activeUserEmail: "",
};

class DefaultNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount = () => {
    this.props.setNavbarHeight(this.refs.navBar.clientHeight);
    this.props.setRef(this.refs.navBar);
    window.onresize = () => {
      //this.props.setWindowResize(window.innerWidth, window.innerHeight);
      this.props.setNavbarHeight(this.refs.navBar.clientHeight);
    };
  };

  onOpenPasswordDialog = () => {
    if (!this.props.isAuthenticated) return;
    const { id, email } = this.props.auth.user;
    this.setState({
      activeUserId: id,
      activeUserEmail: email,
      passwordDialogOpen: true,
    });
  };

  onClosePasswordDialog = () => {
    this.setState(initState);
  };

  onResetPassword = (data) => {
    this.props.userChangePassword(data);
    this.onClosePasswordDialog();
  };

  render() {
    const { classes, renderProp } = this.props;
    const { passwordDialogOpen, activeUserId, activeUserEmail } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} ref="navBar">
          <Toolbar className={classes.toolbar}>
            <div className={classes.logo}>
              <Typography
                variant="h3"
                className={classes.title}
                component={Link}
                to="/"
              >
                ASAP
              </Typography>
              <Typography variant="body1" className={classes.version}>
                {`v${process.env.REACT_APP_VERSION}`}
              </Typography>
            </div>

            <div className={classes.linksGroup}>{renderProp}</div>

            <div className={classes.logGroup}>
              <MessageHandler />
              <LogBtnUser
                isAuthenticated={this.props.isAuthenticated}
                onOpenPasswordDialog={this.onOpenPasswordDialog}
                logout={this.props.logout}
              />
            </div>
          </Toolbar>
        </AppBar>

        {/* Dialogs */}
        <ResetPassword
          open={passwordDialogOpen}
          onCancel={this.onClosePasswordDialog.bind(this)}
          onAccept={this.onResetPassword.bind(this)}
          userId={activeUserId}
          userEmail={activeUserEmail}
          type={AS_USER}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.page,
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setNavbarHeight,
    setWindowResize,
    logout,
    userChangePassword,
    setRef,
  })
)(DefaultNavbar);
