import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import projectsReducer from "./projectsReducer";
import projectViewReducer from "./projectViewReducer";
import recipesReducer from "./recipesReducer";
import simulationReducer from "./simulationReducer";
import bufferReducer from "./bufferReducer";
import homeReducers from "./homeReducers";
import usersReducers from "./usersReducer";
import messageReducer from "./messageReducer";
import imagesReducer from "./imagesReducer";
import iframesReducer from "./iframesReducer";
import projectViewStateReducer from "./projectViewStateReducer";

export default combineReducers({
  page: pageReducer,
  auth: authReducer,
  error: errorReducer,
  projects: projectsReducer,
  projectView: projectViewReducer,
  recipes: recipesReducer,
  simulation: simulationReducer,
  buffer: bufferReducer,
  home: homeReducers,
  users: usersReducers,
  msg: messageReducer,
  images: imagesReducer,
  iframes: iframesReducer,
  projectViewState: projectViewStateReducer,
});
