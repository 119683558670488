export const tokenConfig=(getState)=>{
    //Get token from storage
    const token=getState().auth.token;

    //Headers
    let config={
        headers:{
            "Content-type": "application/json"
        }
    }

    if(token){
        config.headers['x-auth-token']=token;
    }

    return config;
}

export const tokenFileConfig=(getState)=>{

    //Get token from storage
    const token=getState().auth.token;

    //Headers
    let config={
        headers:{
            "Content-type": "multipart/form-data"
        }
    }

    if(token){
        config.headers['x-auth-token']=token;
    }

    return config;
}



