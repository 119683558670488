//Main error block
export const SHOW_ERROR_ID = false;

//Users
export const USER_LEVELS = [
  { text: "Admin", value: "admin" },
  { text: "Free", value: "free" },
  { text: "Premium", value: "premium" },
];

export const NEW_USER = "NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const AS_ADMIN = "AS_ADMIN";
export const AS_USER = "AS_USER";
export const PASSWORD_LENGTH = 8;

export const PRODUCT_GROUP_LIST = [
  { text: "RURAL", value: "RURAL" },
  { text: "URBAN", value: "URBAN" },
  { text: "RESERVOIR", value: "RESERVOIR" },
  { text: "LIVE", value: "LIVE" },
];

export const PROJECT_VISIBILITY = [
  { text: "Public", value: "public" },
  { text: "Private, but visible", value: "private" },
  { text: "Private, not visible", value: "anonymous" },
];

export const PUBLIC_PROJECT = "public";
export const PRIVATE_PROJECT = "private";
export const ANONYMOUS_PROJECT = "anonymous";

export const RURAL = "RURAL";
export const URBAN = "URBAN";
export const RESERVOIR = "RESERVOIR";
export const LIVE = "LIVE";

export const TABS_ORDER = [RURAL, RESERVOIR, URBAN, LIVE];

export const NO_REQUEST = "NO_REQUEST";
export const WAITING = "WAITING";
export const ERROR = "ERROR";
export const SUCCES = "SUCCES";
export const INFO = "INFO";
export const MISSING_PROPS = "MISSING_PROPS";

export const VIEW_STATE_LIST = "LIST";
export const VIEW_STATE_EDIT = "EDIT";
export const VIEW_STATE_ADD_NEW = "ADD_NEW";

export const DANGER_COLOR = "#f44336";

export const BUFFER_UPDATE_RATE = 60 * 1000;

export const WARNING_PICKER = [
  { text: "Extreme", value: "#670099", level: 1 },
  { text: "High", value: "#FF0000", level: 2 },
  { text: "Medium", value: "#FF9900", level: 3 },
  { text: "Low", value: "#FFFF00", level: 4 },
];
export const NO_WARNING = { text: "No warnings", value: "#FFFFFF", level: 100 };

export const OVERLAY_COLOR = [
  { text: "Red", value: "#FF0000" },
  { text: "Green", value: "#00FF00" },
  { text: "Blue", value: "#0000FF" },
];

export const OPERATORS = [
  { text: "<", value: "<" },
  { text: "=", value: "=" },
  { text: ">", value: ">" },
];

//ProjectView

export const COLOR_SCALES = [
  { text: "viridis", value: "viridis" },
  { text: "rainbow", value: "rainbow" },
];

export const DEFAULT_COLOR_SCALE = "viridis";

export const ACTION_STATIC = "STATIC";
export const ACTION_DYNAMIC = "DYNAMIC";
export const ACTION_NULL = null;
export const STATIC_SETTINGS = "STATIC_SETTINGS";
export const DYNAMIC_SETTINGS = "DYNAMIC_SETTINGS";
export const DYNAMIC_COLOR_SCALE = "DYNAMIC_COLOR_SCALE";
export const SIMULATION_MODES = [
  { text: "Color scale", value: DYNAMIC_COLOR_SCALE },
  { text: "Warnings", value: DYNAMIC_SETTINGS },
];

//URLs
export const URL_DOWNLOAD_LOCAL_RECIPE = "/api/projects/downloadrecipe";
export const URL_DOWNLOAD_GLOBAL_RECIPE = "/api/recipes/downloadrecipe";
export const URL_DOWNLOAD_IFRAME_RECIPE = "/api/iframes/downloadiframe";

export const TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SHORT_TIMESTAMP_FORMAT = "YYYY-MM-DD";

//Tbls
export const ADMIN_PROJECT_TBL_COLUMNS = [
  { id: "name", numeric: false, label: "Project name", link: "/projectview" },
  { id: "visibility_formatted", numeric: false, label: "Visibility" },
  // { id: 'home_lat', numeric: true, label: 'Latitude' },
  // { id: 'home_lng', numeric: true, label: 'Longitude' },
  // { id: 'model_ref', numeric: false, label: 'Model reference'},
  { id: "product_group", numeric: false, label: "Product group" },
  { id: "assigned_users", numeric: true, label: "Assigned users" },
  // { id: 'max_users', numeric: true, label: 'Max users' },
  { id: "created", numeric: false, label: "Created" },
  { id: "updated", numeric: false, label: "Updated" },
];

export const USER_PROJECT_TBL_COLUMNS = [
  { id: "name", numeric: false, label: "Project name", link: "/projectview" },
  { id: "visibility_formatted", numeric: false, label: "Visibility" },
  // { id: 'home_lat', numeric: true, label: 'Latitude' },
  // { id: 'home_lng', numeric: true, label: 'Longitude' },
  // { id: 'model_ref', numeric: false, label: 'Model reference'},
  { id: "product_group", numeric: false, label: "Product group" },
  { id: "assigned_users", numeric: true, label: "Assigned users" },
  // { id: 'max_users', numeric: true, label: 'Max users' },
  { id: "created", numeric: false, label: "Created" },
  { id: "updated", numeric: false, label: "Updated" },
];

export const RECIPES_TBL_COLUMNS = [
  { id: "name", numeric: false, label: "Recipe name" },
  { id: "product_group", numeric: false, label: "Product group" },
  { id: "created", numeric: false, label: "Created" },
  { id: "updated", numeric: false, label: "Updated" },
];

export const USERS_TBL_COLUMNS = [
  { id: "first_name", numeric: false, label: "First name" },
  { id: "last_name", numeric: false, label: "Last Name" },
  { id: "email", numeric: false, label: "Email" },
  { id: "level", numeric: false, label: "Level" },
  { id: "company", numeric: false, label: "Company" },
  { id: "last_login_formatted", numeric: false, label: "Last login" },
  { id: "expires_formatted", numeric: false, label: "Expiration date" },
];

export const IFRAMES_TBL_COLUMNS = [
  { id: "name", numeric: false, label: "Name" },
  { id: "model_ref", numeric: false, label: "Model Ref" },
  { id: "url", numeric: false, label: "URL" },
];

export const WARNING_CONTROL_HELP_TEXT =
  "Analyze for warning levels across the entire forecast period";
export const ANIMATION_CONTROL_HELP_TEXT =
  "Analyze for warning levels or values over time";
export const MIN_TIME_DELAY_ANIMATION = 10;

//Map tool tips
export const HRU_TOOL_TIPS = ["FeatureId", "Landuse", "Soil", "SlopeBand"];
export const LSU_TOOL_TIPS = ["FeatureId", "Landscape"];
export const SUB_TOOL_TIPS = ["FeatureId"];
export const RIV_TOOL_TIPS = ["FeatureId", "Subbasin", "Order"];
export const STATIONS_TOOL_TIPS = ["FeatureId"];
export const BATHYMETRY_TOOL_TIPS = ["ASAP_z"];
export const DEFAULT_TIPS = ["FeatureId"];

//plot animation
export const PLOT_ANIMATION_STEP_TIME = 50;

//Screen types
export const MOBILE = "MOBILE";
export const TABLET = "TABLET";
export const DESKTOP = "DESKTOP";

//ProjectViewWorkplace
export const SIM_CONTROL = "SIM_CONTROL";
export const LAYER_CONTROL = "LAYER_CONTROL";
export const WARNING_CONTROL = "WARNING_CONTROL";

//projectViewState
export const SCREEN_VIEW_PANEL = "SCREEN_VIEW_PANEL";
export const SCREEN_VIEW_FULL_SCREEN = "SCREEN_VIEW_FULL_SCREEN";

//Plots
export const NO_DATA_AVAILABLE = "NO_DATA_AVAILABLE";
