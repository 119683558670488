
import moment from 'moment-timezone'

import {
    validateString,
    isEmail,
    isPassword
} from './validation'
import { NEW_USER } from './GlobalConst'


const verifyUserForm=(user)=>{
    
    if(!validateString(user.firstName)){
        return{
            error:true,
            msg:"Please provide first name"
        }
    }

    if(!validateString(user.lastName)){
        return{
            error:true,
            msg:"Please provide last name"
        }
    }

    if(!isEmail(user.email)){
        return{
            error:true,
            msg:"Please provide email"
        }
    }

    if(!isPassword(user.password) && user.type===NEW_USER){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }

    
    if(!user.expires.isValid()){
        return{
            error:true,
            msg:"Please provide expiration date"
        }
    }

    return{
        error:false
    }
} 

const verifyAdminPasswordForm=(data)=>{
    if(!isPassword(data.newPassword1)){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }
    if(!isPassword(data.newPassword2)){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }
    if(data.newPassword2!==data.newPassword1){
        return{
            error:true,
            msg: "New password does not match"
        }
    }

    return {error:false}
}

const verifyUserPasswordForm=(data)=>{
    if(!isPassword(data.oldPassword)){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }
    if(!isPassword(data.newPassword1)){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }
    if(!isPassword(data.newPassword2)){
        return{
            error:true,
            msg:"Please provide password, at least 8 characters long"
        }
    }
    if(data.newPassword2!==data.newPassword1){
        return{
            error:true,
            msg: "New password does not match"
        }
    }

    return {error:false}
}

export {
    verifyUserForm,
    verifyAdminPasswordForm,
    verifyUserPasswordForm
}