import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import {
    verifyAdminPasswordForm
} from '../../others/VerifyUser'

import {
    AS_ADMIN
}from '../../others/GlobalConst';

//Components
import SimpleSelect from './SimpleSelect';

//Material ui
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';






const styles = theme => ({
   
    form:{
        '& > div':{
            marginBottom: theme.spacing(2)
        },
        padding: theme.spacing(0)
    },
    buttons:{
        display:"flex",
        alignItems:"center",
        justifyContent: "space-between"
    },
    contentText:{
        padding:0,
        marginTop: theme.spacing(0),
        margin:0
    }
    
});

const initState={
    oldPassword:'',
    newPassword1:'',
    newPassword2:'',

    error: false,
    errorMsg:''
}

class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state=initState;
    }

  

    /**
     * Events
     */

    onChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onAccept=()=>{
        const {oldPassword, newPassword1, newPassword2}=this.state;
        const {userId}=this.props;

        let verification=verifyAdminPasswordForm({oldPassword, newPassword1, newPassword2, id:userId})
        if(verification.error){
            this.setError(verification.msg);
            return
        }

        this.props.onAccept({oldPassword, newPassword1, newPassword2, id:userId})
        this.onClear();
    }

    onCancel=()=>{
        this.props.onCancel();
        this.onClear();
    }

    onClear=()=>{
        this.setState(initState);
        clearTimeout(this.to);
    }

    /**
     * Functions
     */

    setError=(msg, setOther, clearOther)=>{
        clearTimeout(this.to);
        this.setState({
            error:true,
            errorMsg:msg,
            ...setOther
        })
        let self=this;
        this.to=setTimeout(()=>{
            self.setState({
                error:false,
                errorMsg:'',
                ...clearOther
            });
        }, 3000);
    }

    /**
     * Render component
     */
    render() {
        const {classes, open, type, userEmail, userId}= this.props;
        const {
            oldPassword,
            newPassword1,
            newPassword2,
            error,
            errorMsg
        }=this.state;
        
        const errorText=(error)?(
            <DialogContentText style={{color: "red"}}>
                {errorMsg}
            </DialogContentText>
        ):undefined;

        return (
            
            <Dialog 
                open={open} 
                onClose={this.onCancel.bind(this)} 
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Change password: {userEmail}</DialogTitle>
                <DialogContent>
                    
                    {errorText}
                   
                    <form className={classes.form}>
                        
                        <div>
                            <TextField 
                                autoComplete="new-password"
                                name={"oldPassword"} 
                                size="small" 
                                label="Old password" 
                                variant="outlined"
                                type="password"
                                fullWidth
                                disabled={type === AS_ADMIN}
                                value={oldPassword}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div>
                            <TextField 
                                autoComplete="new-password"
                                name={"newPassword1"} 
                                size="small" 
                                label="New password" 
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={newPassword1}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div>
                            <TextField 
                                autoComplete="new-password"
                                name={"newPassword2"} 
                                size="small" 
                                label="Verify new password" 
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={newPassword2}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        
                    </form>
                      
                </DialogContent>
                <DialogActions className={classes.buttons}>
                        <Button onClick={this.onCancel.bind(this)} color="default" >
                            Cancel
                        </Button>
                        <Button onClick={this.onAccept.bind(this)} color="primary" >
                            Change
                        </Button>
                </DialogActions>
            </Dialog>
            
        )
    }
}

const mapStateToProps=(state)=>({
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, {
    })
  )(ResetPassword);