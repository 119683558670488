import {
  //Project
  SET_PV_METADATA,
  ADD_DECODED_DATA,
  ADD_GEOJSON,
  ADD_REQ_GEOJSON,
  CLEAR_PV,

  //overlay
  UPDATE_OVERLAY,
  SET_OVERLAYS,

  //Tabs
  SET_MAIN_TAB_INDEX,
  SET_SUB_TAB_INDEX,
  SET_TABS,

  //Setting dialog
  OPEN_SETTING_DIALOG,
  CLOSE_SETTING_DIALOG,
  UPDATE_SETTINGS,

  //Controlers
  SET_ACTIVE_CONTROLLER,
} from "../actions/types";

import { updateOrAddObjectBasedOnKey } from "./reducerHelpFunctions";

const initialState = {
  //meta
  homeLat: 0.0,
  homeLng: 0.0,
  zoom: 10,
  projectOpenedAsAdmin: false,
  projectOpenedFromPublic: false,
  projectUUIDs: [],

  //decoded data
  overlays: [],
  tabViews: [],
  settings: [],

  //geojson
  geoJson: [],
  requestedGeoJson: [],

  //tabs controller
  activeMainTab: 0,
  activeSubTab: 0,
  activeTabView: 0,
  _activeSubTabs: [0],
  mainTabs: [],
  subTabs: [],
  tabContainerOpen: false,
  activeController: null,

  //dialogs - new
  openSettingDialog: false,
  activeBlock: null,
  activeValuesSnapshot: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    //Project controllers
    case SET_PV_METADATA:
      return {
        ...state,
        homeLat: action.payload.homeLat,
        homeLng: action.payload.homeLng,
        zoom: action.payload.zoom || 10,
        projectOpenedAsAdmin: action.payload.isAdmin,
        projectOpenedFromPublic: action.payload.fromPublic,
        projectUUIDs: action.payload.projectUUIDs,
      };

    case ADD_DECODED_DATA:
      return {
        ...state,
        overlays: [...state.overlays, ...action.payload.overlays],
        tabViews: [...state.tabViews, ...action.payload.tabViews],
        settings: [...state.settings, ...action.payload.settings],
      };

    case ADD_REQ_GEOJSON:
      return {
        ...state,
        requestedGeoJson: [...state.requestedGeoJson, ...action.payload],
      };

    case ADD_GEOJSON:
      return {
        ...state,
        geoJson: [...state.geoJson, ...action.payload],
      };

    case CLEAR_PV:
      return {
        ...initialState,
      };

    //Overlays

    case UPDATE_OVERLAY:
      return {
        ...state,
        overlays: updateOrAddObjectBasedOnKey(
          state.overlays,
          "overlayId",
          action.payload
        ),
      };

    case SET_OVERLAYS:
      return {
        ...state,
        overlays: action.payload,
      };

    case SET_MAIN_TAB_INDEX:
      return {
        ...state,
        activeTabView: getActiveTabView(
          state,
          action.payload.activeMainTab,
          null
        ),
        activeMainTab: action.payload.activeMainTab,
        activeSubTab: getActiveSubTab(state, action.payload.activeMainTab),
      };

    case SET_SUB_TAB_INDEX:
      return {
        ...state,
        activeTabView: getActiveTabView(
          state,
          null,
          action.payload.activeSubTab
        ),
        activeSubTab: action.payload.activeSubTab,
        _activeSubTabs: updateActiveSubTabs(state, action.payload.activeSubTab),
      };

    case SET_TABS:
      return {
        ...state,
        mainTabs: action.payload.mainTabs,
        subTabs: action.payload.subTabs,
        activeSubTab: 0,
        activeMainTab: 0,
        activeTabView: 0,
        _activeSubTabs: action.payload.mainTabs.map(() => 0),
        _activeTabViews: action.payload.mainTabs.map(() => 0),
      };

    case SET_ACTIVE_CONTROLLER:
      return {
        ...state,
        activeController: action.payload,
      };

    //Setting dialog

    case CLOSE_SETTING_DIALOG:
      return {
        ...state,
        openSettingDialog: false,
        activeBlockId: null,
        activeValuesSnapshot: {},
      };

    case OPEN_SETTING_DIALOG:
      return {
        ...state,
        openSettingDialog: true,
        activeBlockId: action.payload.blockId,
        activeValuesSnapshot: action.payload.valuesSnapshot,
      };

    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: updateSettings(
          state.settings,
          action.payload.settings,
          action.payload.isLocal,
          action.payload.projectUUID
        ),
      };

    default:
      return state;
  }
}

function getActiveTabView(state, main, sub) {
  let _sub = sub;
  let _main = main;

  if (main === null) {
    _main = state.activeMainTab;
  }

  if (sub === null) {
    _sub = state._activeSubTabs[main];
  }

  return state.subTabs[_main][_sub].index;
}

function getActiveSubTab(state, main) {
  return state._activeSubTabs[main];
}

function updateActiveSubTabs(state, sub) {
  return state._activeSubTabs.map((t, i) => {
    if (i === state.activeMainTab) {
      return sub;
    } else {
      return t;
    }
  });
}

function updateSettings(settings, newSettings, isLocal, projectUUID) {
  let updatedSettings = newSettings;

  settings.forEach((setting) => {
    if (
      !(setting.projectUUID === projectUUID && setting.localSetting === isLocal)
    )
      updatedSettings.push(setting);
  });

  return updatedSettings;
}
