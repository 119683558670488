import {
    SET_USERS,
    ADD_NEW_USER,
    UPDATE_USER,
    DELETE_USER,
    SET_USER_REQUEST_STATUS
} from '../actions/types';

import{
    updateOrAddObjectBasedOnKey,
    removeObjectBasedOnKey
}from './reducerHelpFunctions'
import { NO_REQUEST } from '../others/GlobalConst';


const initialState={
    users:[],
    requestStatus:NO_REQUEST
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_USERS:
            return {
                ...state,
                users:action.payload
            }

        case ADD_NEW_USER:
            return {
                ...state,
                users:[action.payload, ...state.users]
            }

        case UPDATE_USER:
            return {
                ...state,
                users:updateOrAddObjectBasedOnKey(state.users, 'id', action.payload)
            }

        case DELETE_USER:
            return {
                ...state,
                users:removeObjectBasedOnKey(state.users, 'id', action.payload.id)
            }

        case SET_USER_REQUEST_STATUS:
            return {
                ...state,
                requestStatus:action.payload
            }

        default:
            return state; 
    }
}



