import {
    SET_ERROR_MSG,
    SET_ERROR_SNACK_BAR,
    CLEAR_ERRORS
} from '../actions/types';

const initialState={
    errorStatus:false,
    errorId:'',
    errorContent:'',
    errorOther:'',
}

export default function(state=initialState, action){
    switch(action.type){
        case SET_ERROR_MSG:
            let {errorId, errorContent, errorOther}=action.payload;
            return{
                ...state,
                errorStatus:true,
                errorId,
                errorContent,
                errorOther
            }
        
        case CLEAR_ERRORS:
            return{
                ...initialState
            }

        case SET_ERROR_SNACK_BAR:
            return{
                ...state,
                errorId: action.payload.errorId,
                errorContent: action.payload.errorContent
            }
      
        default:
            return state;
    }
}