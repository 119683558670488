import {
    SET_SIMULATION_DIALOG,
    PLAY_SIMULATION,
    PAUSE_SIMULATION,
    SET_SIMULATION_INDEX,
    CLEAR_SIMULATION,
    SET_SIMULATION_ACTIVATION,
    MANUAL_SET_SIMULATION_INDEX,
    SET_SIMULATION_META_DATA,
    SET_SIMULATION_STATUS,
    UPDATE_SIMULATION,
    SET_STATUS_RESET_SIMULATION,
    SET_SIMULATION_TYPE_OVERLAY

} from '../actions/types';

import{
    NO_REQUEST,
    DYNAMIC_COLOR_SCALE
} from '../others/GlobalConst';

const initialState={
    timeInterval:1000,
    simulationIndex: 0,
    openSimulationDialog:false,
    simulationPlaying:false,
    simulationActive:false,

    //information from data and recipes
    timeIndex:[],
    rangeMin:null,
    rangeMax:null,
    simulatingOverlayId:null,
    status:NO_REQUEST,
    unit:"N/A",
    type:DYNAMIC_COLOR_SCALE
}

export default function(state=initialState, action){
    switch(action.type){
        //update functions
        case UPDATE_SIMULATION:
            return{
                ...state,
                ...action.payload
            }

        case SET_SIMULATION_META_DATA:
            return{
                ...state,
                timeIndex:action.payload.timeIndex,
                rangeMin:action.payload.rangeMin,
                rangeMax:action.payload.rangeMax,
                simulatingOverlayId:action.payload.simulatingOverlayId,
                unit:action.payload.unit,
                type:action.payload.type
            }

        case SET_SIMULATION_TYPE_OVERLAY:
            return{
                ...state,
                simulatingOverlayId:action.payload.simulatingOverlayId,
                type:action.payload.type
            }

        case SET_SIMULATION_STATUS:
            return {
                ...state,
                status:action.payload
            }

        case SET_STATUS_RESET_SIMULATION:
            return {
                ...state,
                timeIndex:[],
                rangeMin:null,
                rangeMax:null,
                simulatingOverlayId:null,
                status:action.payload,
                unit:"N/A",
                type:DYNAMIC_COLOR_SCALE,
                simulationIndex: 0,
                simulationPlaying:false,
                simulationActive:false,
            }

        //Dialog
        case SET_SIMULATION_DIALOG:
            return{
                ...state,
                openSimulationDialog:action.payload
            }

        //Simulations

        case SET_SIMULATION_ACTIVATION:
            return{
                ...state,
                simulationActive:action.payload
            }

        case PLAY_SIMULATION:
            return {
                ...state,
                simulationPlaying:true,
                timeInterval:action.payload
            }

        case PAUSE_SIMULATION:
            return{
                ...state,
                simulationPlaying:false,
            }

        case SET_SIMULATION_INDEX:
            return{
                ...state,
                simulationIndex:action.payload
            }

        case MANUAL_SET_SIMULATION_INDEX:
            return{
                ...state,
                simulationIndex:action.payload,
                simulationPlaying:false
            }
        
        case CLEAR_SIMULATION:
            return{
                ...initialState
            }

        default:
            return state; 
    }
}




