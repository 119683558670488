import {
    ADD_NEW_IFRAME,
    SET_IFRAMES,
    UPDATE_IFRAME,
    DELETE_IFRAME
} from '../actions/types';

import{
    updateOrAddObjectBasedOnKey,
    removeObjectBasedOnKey
}from './reducerHelpFunctions'

const initialState={
    iframes:[]
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_IFRAMES:
            return {
                ...state,
                iframes:action.payload
            }

        case ADD_NEW_IFRAME:
            return{
                ...state,
                iframes:[...state.iframes, action.payload]
            }

        case UPDATE_IFRAME:
            return{
                ...state,
                iframes:updateOrAddObjectBasedOnKey(state.iframes, 'id', action.payload)
            }

        case DELETE_IFRAME:
            return{
                ...state,
                iframes:removeObjectBasedOnKey(state.iframes, 'id', action.payload.id)
            }
 
        default:
            return state; 
    }
}



