import {
    CLEAR_ERRORS, 
    SET_ERROR_MSG,
    SET_ERROR_SNACK_BAR
} from './types';

export const getErrorSnackBar=(error, errorId)=>{
    if(!error.response || !error.response.data){
        console.log(error)
        return{
            type:SET_ERROR_SNACK_BAR,
            payload:{errorContent: "System error"}
        }
    }

    if(errorId){
        const {errorContent}=error.response.data;
        return{
            type:SET_ERROR_SNACK_BAR,
            payload:{errorContent, errorId}
        }
    }

    return{
        type:SET_ERROR_SNACK_BAR,
        payload:error.response.data
    }
}

export const getErrorMsg=(error)=>{

    if(!error.response || !error.response.data){
        console.log(error)
        return{
            type:SET_ERROR_MSG,
            payload:{errorContent: "System error"}
        }
    }
    
    return{
        type:SET_ERROR_MSG,
        payload:error.response.data
    }
}

export const setErrorMsg=(error)=>(dispatch)=>{
    dispatch(
        getErrorMsg(error)
    )
}

export const getAndSetErrorMsg=(error)=>(dispatch)=>{

    if(!error.response || !error.response.data){
        console.log(error)
        dispatch({
            type:SET_ERROR_MSG,
            payload:{errorContent: "System error"}
        })
        return
    }
    
    dispatch({
        type:SET_ERROR_MSG,
        payload:error.response.data
    })
}



export const clearErrors=()=>{
    return{
        type: CLEAR_ERRORS
    }
}