import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux"

const PrivateProjectViewRoute=({component: Component, auth, pv, ...rest})=> {
    return(
        <Route 
            {...rest}
            render={props=>{
                if(auth.isLoading){
                    return <h2>Loading...</h2>
                }else if(!auth.isAuthenticated){
                    return <Redirect  to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}/>
                }else if(pv.projectUUIDs.length===0){
                    return <Redirect  to={{
                        pathname: "/workspace",
                        state: { from: '/workspace' }
                    }}/>
                }else{
                    return <Component {...props}/>
                }
            }}
        />
)}

const mapStateToProps=state=>({
    auth: state.auth,
    pv: state.projectView
})

export default connect(mapStateToProps, {})(PrivateProjectViewRoute); 
