
import {
    PASSWORD_LENGTH
} from './GlobalConst'

let validateString=(str)=>{
    let strLight=str.replace(/ /g,'');
    return (strLight==='')?false:true;
}

let isEmail=(str)=>{
    return str.includes('@');
}

let isPassword=(password)=>{
    if(!validateString(password))
        return false

    if(password.length<PASSWORD_LENGTH)
        return false;

    return true;
}

let validateUser=(user, users)=>{

    if(!validateString(user))
        return "NO_TEXT";

    if(!isEmail(user))
        return "NOT_EMAIL";

    return "SUCCES";
}

let validateNames=(name, names)=>{
    if(!validateString(name))
        return "NO_TEXT";

    if(names.indexOf(name)>-1)
        return "EXIST";

    return "SUCCES";
}

export{
    isEmail,
    validateString,
    validateUser,
    validateNames,
    isPassword
}