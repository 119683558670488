import axios from 'axios';

import {getErrorSnackBar} from '../actions/errorActions'

import {
    USER_LOADED, 
    USER_LOADING, 
    AUTH_ERROR, 
    REGISTER_SUCCESS, 
    REGISTER_FAIL,
    LOGOUT_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SET_ERROR_SNACK_BAR
} from './types';

// check token and load user
export const loadUser=()=>(dispatch, getState)=>{
    //User loading
    dispatch({
        type:USER_LOADING
    });

    axios.get('/api/auth/user', tokenConfig(getState))
        .then(res=>dispatch({
            type:USER_LOADED,
            payload: res.data
        }))
        .catch(err=>{
            dispatch(getErrorSnackBar(err))
            dispatch({
                type:AUTH_ERROR
            })
        })
    
}

export const getToken=()=>(dispatch, getState)=>{
    return getState().auth.token;
}

//Logout
export const logout=()=>{
    return {
        type: LOGOUT_SUCCESS
    } 
}

//Login
export const login=({email, password})=>dispatch=>{
    const config={
        headers:{
            'Content-Type': 'application/json'
        }
    }

    const body=JSON.stringify({email, password});

    axios.post('api/auth', body, config)
        .then(res=>{
            dispatch({
                type:LOGIN_SUCCESS,
                payload:res.data
            });

        })
        .catch(err=>{
            dispatch(getErrorSnackBar(err, 'LOGIN_FAIL'))
            dispatch({
                type: LOGIN_FAIL
            });
        })
}


//register user
export const register=({email, password, level, firstName, lastName})=>dispatch=>{
    const config={
        headers:{
            'Content-Type': 'application/json'
        }
    }

    const body=JSON.stringify({email, password, level, firstName, lastName});

    axios.post('api/users', body, config)
        .then(res=>{
            dispatch({
                type:REGISTER_SUCCESS,
                payload:res.data
            });

        })
        .catch(err=>{
            dispatch(getErrorSnackBar(err, 'REGISTER_FAIL'))
            dispatch({
                type: REGISTER_FAIL
            });
        })
}


//Setup config/headers token
export const tokenConfig=(getState)=>{
    //Get token from storage
    const token=getState().auth.token;

    //Headers
    let config={
        headers:{
            "Content-type": "application/json"
        }
    }

    if(token){
        config.headers['x-auth-token']=token;
    }

    return config;
}