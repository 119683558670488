import React, { Component, Fragment, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";

//Material-ui
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import cyan from "@material-ui/core/colors/cyan";

//Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";
import { startUpdateRoutine, stopUpdateRoutine } from "./actions/bufferActions";

//App
import PrivateRoute from "./components/common/PrivateRoute";
import PrivateProjectViewRoute from "./components/common/PrivateProjectViewRoute";
import PrivateAdminRoute from "./components/common/PrivateAdminRoute";
import MainErrorHandler from "./components/common/MainErrorHandler";

import UserAdminNavbar from "./components/navbar/UserAdminNavbar";
import MobileNavbar from "./components/navbar/MobileNavbar";
import PageLoader from "./components/common/PageLoader";

//React lazy load
const ProjectViewWorkplace = lazy(() =>
  import("./components/ProjectViewWorkplace")
);
const HomeWorkPlace = lazy(() => import("./components/HomeWorkPlace"));
const LoginWorkPlace = lazy(() => import("./components/auth/LoginWorkPlace"));
const RegisterWorkPlace = lazy(() =>
  import("./components/auth/RegisterWorkPlace")
);
const WorkspaceWorkPlace = lazy(() =>
  import("./components/WorkspaceWorkPlace")
);
const AdminWorkPlace = lazy(() => import("./components/AdminWorkPlace"));
const MobilePage = lazy(() => import("./components/mobileSpace2/MobilePage"));
const IFrameContainer = lazy(() =>
  import("./components/iframes/IFrameContainer")
);
//const UserAdminNavbar=lazy(()=>import('./components/navbar/UserAdminNavbar'));

//CSS
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2F5778", //"#172B3C", //cyan[800], //'#31859D',
      contrastText: "#ffffff", //getContrastText(palette.primary[800])
    },
    secondary: {
      main: "#ffffff",
    },
    danger: {
      main: "#ff0000",
    },
  },
});

class App extends Component {
  componentDidMount() {
    console.log(process.env.REACT_APP_VERSION);
    store.dispatch(startUpdateRoutine());
    store.dispatch(loadUser());
  }

  componentWillUnmount() {
    store.dispatch(stopUpdateRoutine());
  }

  render() {
    return (
      <Suspense fallback={<PageLoader />}>
        <Router>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <div className="App" id="AppWrapper">
                <Route
                  path="/login"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <LoginWorkPlace {...props} />
                    </Fragment>
                  )}
                />

                <Route
                  path="/register"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <RegisterWorkPlace {...props} />
                    </Fragment>
                  )}
                />

                <Route
                  path="/public"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <ProjectViewWorkplace {...props} />
                    </Fragment>
                  )}
                />

                <Route
                  path="/mobile/:lake"
                  component={(props) => (
                    <Fragment>
                      <MobileNavbar router={props} />
                      <MobilePage {...props} />
                    </Fragment>
                  )}
                />

                <PrivateRoute
                  path="/workspace"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <WorkspaceWorkPlace {...props} />
                    </Fragment>
                  )}
                />

                <PrivateProjectViewRoute
                  path="/projectview"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <ProjectViewWorkplace {...props} />
                    </Fragment>
                  )}
                />

                <PrivateAdminRoute
                  path="/admin"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <AdminWorkPlace {...props} />
                    </Fragment>
                  )}
                />

                <Route
                  exact
                  path="/"
                  component={(props) => (
                    <Fragment>
                      <UserAdminNavbar router={props} />
                      <HomeWorkPlace {...props} />
                    </Fragment>
                  )}
                />

                <Route
                  exact
                  path="/plots/:id"
                  component={(props) => (
                    <Fragment>
                      <IFrameContainer {...props} />
                    </Fragment>
                  )}
                />

                <MainErrorHandler />
              </div>
            </ThemeProvider>
          </Provider>
        </Router>
      </Suspense>
    );
  }
}

export default App;
