import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { clearErrors } from "../../actions/errorActions";
import { logout } from "../../actions/authActions";

import { SHOW_ERROR_ID } from "../../others/GlobalConst";

//Material ui
import { withStyles } from "@material-ui/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({});

const defaultError = (
  <p style={{ margin: "0px" }}>
    This has impacted our website. We are sorry for the inconvenience.
    <br />
    We are working hard to have it fixed as soon as possible.
    <br />
    Please try again later or{" "}
    <a href="https://waterwebtools.com/contact.html" target="_blank">
      contact our support.
    </a>
  </p>
);

class MainErrorHandler extends Component {
  constructor(props) {
    super(props);
  }

  getErrorBlock = (error, errorId, useId) => {
    let errorContent = defaultError;
    let useDefaultError = true;
    //Always use default error
    if (error && error.message) {
      useDefaultError = false;
      errorContent = error.message;
    }
    // else if (typeof error === "string" && error.indexOf("HTML") === -1) {
    //   useDefaultError = false;
    //   errorContent = error;
    // }

    return {
      header: useDefaultError
        ? `We’re currently experiencing a technical issue.`
        : "Ups, an error occurred",
      body: (
        <Typography component="div">
          <DialogContentText>
            {errorContent}
            <br />
            {useId ? errorId : null}
          </DialogContentText>
        </Typography>
      ),
    };
  };

  onClose = () => {
    if (this.props.error.errorId === "TOKEN_NOT_VALID") {
      this.props.logout();
    }
    this.props.clearErrors();
  };

  render() {
    const { classes } = this.props;
    const { errorStatus, errorId, errorContent, errorOther } = this.props.error;

    const errorBlock = this.getErrorBlock(errorContent, errorId, SHOW_ERROR_ID);

    //Print error to console
    if (errorId !== "" || errorContent !== "") {
      console.log(`Error ID: ${errorId}`);
      console.log(`Error Content: 
    
    ${errorContent}`);
    }

    return (
      <Dialog open={errorStatus} onClose={this.onClose} size="sm" fullWidth>
        <DialogTitle id="simple-dialog-title">{errorBlock.header}</DialogTitle>
        <DialogContent>{errorBlock.body}</DialogContent>
        <DialogActions>
          <Button onClick={this.onClose.bind(this)} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    clearErrors,
    logout,
  })
)(MainErrorHandler);
