import React, { Component } from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import {
    WAITING,
    SUCCES, 
    ERROR,
    INFO
} from '../../others/GlobalConst'

//Material ui
import { withStyles } from '@material-ui/styles';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core';


const styles = theme => ({
    container:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingRight:theme.spacing(4)
    },
    visualItem:{
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(2),
    }
});


class MessageHandler extends Component {
    constructor(props){
        super(props);

    }

    getVisualItem=(type)=>{
        //style={{height:'20px', width:'20px'}}
        switch(type){
            case WAITING:
                return (<div ><CircularProgress size={30} color="secondary"/></div>)

            case SUCCES:
                return (<DoneIcon color="secondary"/>);

            case ERROR:
                return (<ErrorOutlineIcon color="secondary"/>);

            case INFO:
                return (<InfoIcon color="secondary"/>);


            default:
                return (null);
        }
    }
   
    render() {
        const {classes}= this.props;
        const {type, message}=this.props.msg
        
        const visualItem=this.getVisualItem(type)
        return (
            <div className={classes.container}>
                <Typography variant="subtitle1">
                    {message}
                </Typography>
                <div className={classes.visualItem}>
                    {visualItem}
                </div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    msg: state.msg
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, {
    })
  )(MessageHandler);