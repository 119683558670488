import {
    SET_HOME_PROJECTS,
    CLEAR_HOME_PROJECTS
} from '../actions/types';


const initialState={
    projects:[],
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_HOME_PROJECTS:
            return{
                ...state,
                projects:action.payload
            }

        case CLEAR_HOME_PROJECTS:
            return {
                ...initialState
            }

        default:
            return state; 
    }
}
