import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux"

const PrivateAdminRoute=({component: Component, auth, ...rest})=> (
    <Route 
        {...rest}
        render={props=>{
            if(auth.isLoading){
                return <h2>Loading...</h2>
            }else if(!auth.isAuthenticated){
                return <Redirect to={{
                    pathname: "/login",
                    state: { from: props.location }
                }}/>
            }else if(auth.user.level==='admin'){
                return <Component {...props}/>
            }else{
                alert('You need admin rights!');
                return <Redirect to="/"/>
            }
        }}
    />
)

const mapStateToProps=state=>({
    auth: state.auth
})

export default connect(mapStateToProps, {})(PrivateAdminRoute); 
