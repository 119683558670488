import {
  ADD_BUFFER_DATA,
  SET_BUFFER_STATUS,
  SET_BUFFER,
  CLEAR_BUFFER,
  START_UPDATE_ROUTINE,
  STOP_UPDATE_ROUTINE,
  CLEAR_EXPIRED_DATA,
  UPDATE_BUFFER_DATA,
} from "../actions/types";

import {
  removeObjectBasedOnKey,
  updateOrAddObjectBasedOnKey,
} from "./reducerHelpFunctions";

const initialState = {
  buffer: [],
  bufferStatus: [],
  runUpdateBuffer: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    //Project controllers
    case ADD_BUFFER_DATA:
      return {
        ...state,
        buffer: [...state.buffer, action.payload],
        bufferStatus: removeObjectBasedOnKey(
          state.bufferStatus,
          "queryHash",
          action.payload.queryHash
        ),
      };

    case UPDATE_BUFFER_DATA:
      return {
        ...state,
        buffer: updateOrAddObjectBasedOnKey(state.buffer, "id", action.payload),
        bufferStatus: removeObjectBasedOnKey(
          state.bufferStatus,
          "queryHash",
          action.payload.queryHash
        ),
      };

    case SET_BUFFER_STATUS:
      return {
        ...state,
        bufferStatus: updateOrAddObjectBasedOnKey(
          state.bufferStatus,
          "queryHash",
          action.payload
        ),
      };

    case CLEAR_BUFFER:
      return {
        buffer: [],
        bufferStatus: [],
      };

    case SET_BUFFER:
      return {
        ...state,
        buffer: action.payload,
      };

    case START_UPDATE_ROUTINE:
      return {
        ...state,
        runUpdateBuffer: true,
      };

    case STOP_UPDATE_ROUTINE:
      return {
        ...state,
        runUpdateBuffer: false,
      };

    case CLEAR_EXPIRED_DATA:
      return {
        ...state,
        buffer: removeObjectBasedOnKey(
          state.buffer,
          "queryHash",
          action.payload
        ),
      };

    default:
      return state;
  }
}
