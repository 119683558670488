import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';

//Components
import DefaultNavbar from './DefaultNavbar'

//Material UI
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const styles=theme=>({
    root:{
        flexGrow:1,
        zIndex:theme.zIndex.drawer+1
    }
})


class UserAdminNavbar extends Component {
    constructor(props){
        super(props)
    }

    isUserAdmin=()=>{
        const {auth}=this.props;
        return (auth.user!=undefined && auth.user.level==='admin')
    }

    getUserBtns=()=>{
        return(<Button color="secondary" size="large" component={Link} to="/workspace">Workspace</Button>)
    }

    getAdminBtns=()=>{
        return(
            <Fragment>
                <Button color="secondary" size="large" component={Link} to="/workspace">Workspace</Button>
                <Button color="secondary" size="large" component={Link} to="/admin">Admin</Button>
            </Fragment>
        )
    }

    getBtns=()=>{
        const {isAuthenticated}=this.props;

        if(isAuthenticated && this.isUserAdmin()){
            return this.getAdminBtns();
        }else if(isAuthenticated    ){
            return this.getUserBtns();
        }else{
            return null
        }
    }

    render() {
        const btns=this.getBtns();

        return (
            <DefaultNavbar
                {...this.props.router}
                renderProp={btns}
            />
        )
    }
}

const mapStateToProps=(state)=>({
    isAuthenticated: state.auth.isAuthenticated,
    auth:state.auth
})
  
export default compose(
    withStyles(styles),
    connect(mapStateToProps, {
    })
)(UserAdminNavbar);
