import {
    ADD_NEW_RECIPE,
    SET_RECIPES,
    UPDATE_RECIPE,
    DELETE_RECIPE
} from '../actions/types';

import{
    updateOrAddObjectBasedOnKey,
    removeObjectBasedOnKey
}from './reducerHelpFunctions'

const initialState={
    recipes:[]
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_RECIPES:
            return {
                ...state,
                recipes:action.payload
            }

        case ADD_NEW_RECIPE:
            return{
                ...state,
                recipes:[...state.recipes, action.payload]
            }

        case UPDATE_RECIPE:
            return{
                ...state,
                recipes:updateOrAddObjectBasedOnKey(state.recipes, 'id', action.payload)
            }

        case DELETE_RECIPE:
            return{
                ...state,
                recipes:removeObjectBasedOnKey(state.recipes, 'id', action.payload.id)
            }
 
        default:
            return state; 
    }
}



