import React, { Component, Fragment } from 'react'
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';

//materialUI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles=theme=>({})

class LogBtnUser extends Component {
    constructor(props){
        super(props);
        this.state={
			menuOpen:false,
			anchorEl:null
        }
    }

    getLogButton=(isAuthenticated)=>{
        if(this.props.isAuthenticated){
            return(<Button 
                        color="secondary" 
                        size="large" 
                        onClick={()=>this.props.logout()}
                        >Logout</Button>)
        }else{
            return ( <Button 
                        color="secondary" 
                        size="large" 
                        component={Link} 
                        to="/login">Login</Button>)
        }
    }

    onOpenMenu=(e)=>{
		if(!this.props.isAuthenticated)
			return;

		this.setState({
            menuOpen:true,
            anchorEl:e.currentTarget
		})
    }
    
    onCloseMenu=()=>{
		this.setState({
			menuOpen:false,
			anchorEl:null
		})
    }
    
    onOpenDialog=()=>{
        this.setState({
            menuOpen:false,
			anchorEl:null
        })
        this.props.onOpenPasswordDialog();
    }

    render() {
        const {menuOpen, anchorEl}=this.state;
        const btnLoginLogout=this.getLogButton();

        return (
            <Fragment>
                {btnLoginLogout}
                <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.onOpenMenu.bind(this)}
                >
                    <AccountCircleIcon color="secondary" fontSize="large"/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    id="menu-appbar"
                    open={menuOpen}
                    onClose={this.onCloseMenu.bind(this)}
                >
                    <MenuItem onClick={()=>this.onOpenDialog()}>Change password</MenuItem>
                </Menu>
            </Fragment>
        )
    }
}

export default compose(
    withStyles(styles)
)(LogBtnUser);