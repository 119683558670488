import {
    SET_USERS,
    ADD_NEW_USER,
    UPDATE_USER,
    DELETE_USER,
    SET_USER_REQUEST_STATUS
} from './types';

import axios from 'axios';
import {tokenConfig} from './tokenConfig';
import {getErrorMsg} from './errorActions'
import moment from 'moment-timezone'
import { 
    WAITING,
    SUCCES,
    ERROR
 } from '../others/GlobalConst';
 import{
    setSuccesMessage
} from './messageActions'

const setRequestStatus=(status)=>(dispatch, getState)=>{
    dispatch({
        type: SET_USER_REQUEST_STATUS,
        payload: status
    })
}

export const getUsers=()=>(dispatch, getState)=>{

    axios.get('/api/users/all', tokenConfig(getState))
        .then(res=>{
            dispatch({
                type: SET_USERS,
                payload: res.data.data
            });
        })
        .catch(err=>{
            dispatch(getErrorMsg(err));
        })
}


export const addNewUser=(user)=>(dispatch, getState)=>{
    dispatch(setRequestStatus(WAITING))
    // let convertedUsers={
    //     ...user,
    //     expires:user.expires.toISOString()
    // }
    axios.post('/api/users/newuser', user, tokenConfig(getState))
    .then(res=>{
        dispatch({
            type: ADD_NEW_USER,
            payload: res.data.data
        });
        dispatch(setRequestStatus(SUCCES))
    })
    .catch(err=>{
        dispatch(setRequestStatus(ERROR))
        dispatch(getErrorMsg(err));
    })
}

export const updateUser=(user)=>(dispatch, getState)=>{
    dispatch(setRequestStatus(WAITING))
    axios.post('/api/users/updateuser', user, tokenConfig(getState))
    .then(res=>{
        dispatch({
            type: UPDATE_USER,
            payload: res.data.data
        });
        dispatch(setRequestStatus(SUCCES))
    })
    .catch(err=>{
        dispatch(setRequestStatus(ERROR))
        dispatch(getErrorMsg(err));
    })
}

export const deleteUser=(id)=>(dispatch, getState)=>{
    dispatch(setRequestStatus(WAITING))
    axios.post('/api/users/deleteuser', {id}, tokenConfig(getState))
    .then(res=>{
        dispatch({
            type: DELETE_USER,
            payload: res.data.data
        });
        dispatch(setRequestStatus(SUCCES))
    })
    .catch(err=>{
        dispatch(getErrorMsg(err));
        dispatch(setRequestStatus(ERROR))
    })
}



export const adminChangePassword=(data)=>(dispatch, getState)=>{
    axios.post('/api/users/adminchangepassword', data, tokenConfig(getState))
    .then(res=>{
        dispatch(setSuccesMessage('Password changed!'))
    })
    .catch(err=>{
        dispatch(getErrorMsg(err));
    })
}

export const userChangePassword=(data)=>(dispatch, getState)=>{
    axios.post('/api/users/userchangepassword', data, tokenConfig(getState))
    .then(res=>{
        dispatch(setSuccesMessage('Password changed!'))
    })
    .catch(err=>{
        dispatch(getErrorMsg(err));
    })
}

