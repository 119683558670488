import {
    CLEAR_MESSAGE,
    SET_MESSAGE
} from '../actions/types';

const initialState={
    message:'',
    type:null
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_MESSAGE:
            return{
                ...state,
                message:action.payload.message,
                type:action.payload.type
            }

        case CLEAR_MESSAGE:
            return initialState;
       
        default:
            return state;
    }
}