
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
 
}));

export default function SimpleSelect(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const getMenuItems=(items)=>{
        return items.map((item, index)=>(<MenuItem key={index} value={item.value}>{item.text}</MenuItem>))
    }
  
    return (      
        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
            <InputLabel ref={inputLabel} id="label-id">
                {props.label}
            </InputLabel>
            <Select
                labelId="label-id"
                value={props.value}
                onChange={props.onChange}
                labelWidth={labelWidth}
                name={props.name}
                disabled={(props.disabled)?true:false}
            >
                {getMenuItems(props.items)}
            </Select>
        </FormControl>
  );
}


