import {
  REQ_TAB_SCREEN_MODE,
  REQ_TAB_CONTAINER,
  SET_TAB_SCREEN_MODE,
  SET_TAB_CONTAINER,
  SET_FULL_SCREEN_BTN,
  SET_ONLY_SCREEN_VIEW,
  CLEAR_VIEW_STATE,
} from "../actions/types";

const initialState = {
  reqTabContainerOpen: false,
  reqTabFullScreenMode: false,
  tabContainerOpen: false,
  tabFullScreenMode: false,
  showFullScreenBtn: false,
  onlyScreenView: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQ_TAB_SCREEN_MODE:
      return {
        ...state,
        reqTabFullScreenMode: action.payload,
      };

    case REQ_TAB_CONTAINER:
      return {
        ...state,
        reqTabContainerOpen: action.payload,
      };
    case SET_TAB_SCREEN_MODE:
      return {
        ...state,
        tabFullScreenMode: action.payload,
      };

    case SET_TAB_CONTAINER:
      return {
        ...state,
        tabContainerOpen: action.payload,
      };

    case SET_FULL_SCREEN_BTN:
      return {
        ...state,
        showFullScreenBtn: action.payload,
      };

    case SET_ONLY_SCREEN_VIEW:
      return {
        ...state,
        onlyScreenView: action.payload,
      };

    case CLEAR_VIEW_STATE:
      return initialState;

    default:
      return state;
  }
}
