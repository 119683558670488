import {
    SET_PROJECTS,
    SET_SHORT_FORMATTED_RECIPES,
    SET_GEOJSON_FILES,
    ADD_GEOJSON_FILES,
    DELETE_GEOJSON_FILE,
    ADDED_NEW_PROJECT,
    UPDATED_PROJECT,
    SET_ACTIVE_PROJECT,
    CLEAR_ACTIVE_PROJECT,
    ADD_MEMBER_TO_PROJECT,
    SET_MEMBERS_ON_PROJECT,
    DELETE_MEMBER_FROM_PROJECT,
    CHANGE_USER_RIGHT,
    DELETE_PROJECT,
    CLEAR_PROJECTS,
    SET_PROJECT_REQUEST_STATUS
} from '../actions/types';

import{
    updateOrAddObjectBasedOnKey,
    removeObjectBasedOnKey
}from './reducerHelpFunctions'

import { NO_REQUEST } from '../others/GlobalConst';


const initialState={
    projects:[],
    recipes:[],
    settings:[],
    activeProjectUUID:'',
    activeProjectName:'',
    activeProjectFiles:[],
    activeProjectMembers:[],
    requestStatus:NO_REQUEST
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_PROJECT_REQUEST_STATUS:
            return {
                ...state,
                requestStatus:action.payload
            }

        case SET_SHORT_FORMATTED_RECIPES:
            return{
                ...state,
                recipes:action.payload
            }
        
        case ADDED_NEW_PROJECT:
            return{
                ...state,
                projects:[...state.projects, action.payload]
            }

        case UPDATED_PROJECT:
            return{
                ...state,
                projects: updateOrAddObjectBasedOnKey(state.projects, 'uuid', action.payload)
            }

        case DELETE_PROJECT:
            return{
                ...state,
                projects: removeObjectBasedOnKey(state.projects, 'uuid', action.payload)
            }

        case ADD_GEOJSON_FILES:
            return {
                ...state,
                activeProjectFiles: [...state.activeProjectFiles, action.payload]
            }

        case SET_GEOJSON_FILES:
            return{
                ...state,
                activeProjectFiles: action.payload
            }

        case DELETE_GEOJSON_FILE:
            return{
                ...state,
                activeProjectFiles:removeObjectBasedOnKey(state.activeProjectFiles, 'id', action.payload.id)
            }

        case SET_MEMBERS_ON_PROJECT:
            return{
                ...state,
                activeProjectMembers: action.payload
            }

        case ADD_MEMBER_TO_PROJECT:
            return{
                ...state,
                activeProjectMembers: [...state.activeProjectMembers, action.payload]
            }

        case DELETE_MEMBER_FROM_PROJECT:
            return{
                ...state,
                activeProjectMembers:removeObjectBasedOnKey(
                    state.activeProjectMembers,
                    'member_id',
                    action.payload.member_id)
            }

        case CHANGE_USER_RIGHT:
            return {
                ...state,
                activeProjectMembers:updateOrAddObjectBasedOnKey(state.activeProjectMembers, 'member_id',action.payload)
            }

        case SET_ACTIVE_PROJECT:
            return{
                ...state,
                activeProjectUUID: action.payload.projectUUID,
                activeProjectName: action.payload.projectName
            }

        case CLEAR_ACTIVE_PROJECT:
            return{
                ...state,
                activeProjectUUID: '',
                activeProjectName: '',
                activeProjectFiles:[]
            }

        case CLEAR_PROJECTS:
            return{
                ...initialState
            }

        case SET_PROJECTS:
            return{
                ...state,
                projects:action.payload
            }

        default:
            return state; 
    }
}
