import React, { Component } from 'react'
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({  
    progressBar:{
        width:'100%',
        height:'20px',
    }
});

class PageLoader extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {classes}=this.props;
        return (
            <div className={classes.progressBar}>
                <LinearProgress variant="indeterminate"/>
            </div>
        )
    }
}

export default compose(
    withStyles(styles)
)(PageLoader);