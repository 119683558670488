//Auth
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

//Buffer
export const ADD_BUFFER_DATA = "ADD_BUFFER_DATA";
export const UPDATE_BUFFER_DATA = "UPDATE_BUFFER_DATA";
export const SET_BUFFER_STATUS = "SET_BUFFER_STATUS";
export const CLEAR_BUFFER = "CLEAR_BUFFER";
export const SET_BUFFER = "SET_BUFFER";
export const CLEAR_EXPIRED_DATA = "CLEAR_EXPIRED_DATA";
export const START_UPDATE_ROUTINE = "START_UPDATE_ROUTINE";
export const STOP_UPDATE_ROUTINE = "STOP_UPDATE_ROUTINE";

//Error
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const SET_ERROR_SNACK_BAR = "SET_ERROR_SNACK_BAR";

//Page
export const SET_NAVBAR_HEIGHT = "SET_NAVBAR_HEIGHT";
export const SET_TABVIEW_WIDTH = "SET_TABVIEW_WIDTH";
export const SET_NAVBAR_REF = "SET_NAVBAR_REF";
export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";

//Projects
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_SHORT_FORMATTED_RECIPES = "SET_SHORT_FORMATTED_RECIPES";
export const SET_GEOJSON_FILES = "SET_GEOJSON_FILES";
export const ADD_GEOJSON_FILES = "ADD_GEOJSON_FILES";
export const DELETE_GEOJSON_FILE = "DELETE_GEOJSON_FILE";
export const ADDED_NEW_PROJECT = "ADDED_NEW_PROJECT";
export const UPDATED_PROJECT = "UPDATED_PROJECT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const CLEAR_ACTIVE_PROJECT = "CLEAR_ACTIVE_PROJECT";
export const ADD_MEMBER_TO_PROJECT = "ADD_MEMBER_TO_PROJECT";
export const SET_MEMBERS_ON_PROJECT = "SET_MEMBERS_ON_PROJECT";
export const DELETE_MEMBER_FROM_PROJECT = "DELETE_MEMBER_FROM_PROJECT";
export const CHANGE_USER_RIGHT = "CHANGE_USER_RIGHT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const SET_PROJECT_REQUEST_STATUS = "SET_PROJECT_REQUEST_STATUS";

//Recipes
export const ADD_NEW_RECIPE = "ADDED_NEW_RECIPE";
export const SET_RECIPES = "SET_RECIPES";
export const UPDATE_RECIPE = "UPDATE_RECIPE";
export const DELETE_RECIPE = "DELETE_RECIPE";

//Settings
export const ADD_NEW_SETTING = "ADDED_NEW_SETTING";
export const SET_SETTINGS = "SET_SETTINGS";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const DELETE_SETTING = "DELETE_SETTING";

//Simulations
export const SET_SIMULATION_DIALOG = "SET_SIMULATION_DIALOG";
export const PLAY_SIMULATION = "PLAY_SIMULATION";
export const PAUSE_SIMULATION = "PAUSE_SIMULATION";
export const SET_SIMULATION_INDEX = "SET_SIMULATION_INDEX";
export const CLEAR_SIMULATION = "CLEAR_SIMULATION";
export const SET_SIMULATION_ACTIVATION = "SET_SIMULATION_ACTIVATION";
export const MANUAL_SET_SIMULATION_INDEX = "MANUAL_SET_SIMULATION_INDEX";
export const SET_SIMULATION_META_DATA = "SET_SIMULATION_META_DATA";
export const SET_SIMULATION_STATUS = "SET_SIMULATION_STATUS";
export const UPDATE_SIMULATION = "UPDATE_SIMULATION";
export const SET_STATUS_RESET_SIMULATION = "SET_STATUS_RESET_SIMULATION";
export const SET_SIMULATION_TYPE_OVERLAY = "SET_SIMULATION_TYPE_OVERLAY";

//New projectView
export const SET_PV_METADATA = "SET_PV_METADATA";
export const ADD_DECODED_DATA = "ADD_DECODED_DATA";
export const ADD_GEOJSON = "ADD_GEOJSON";
export const ADD_REQ_GEOJSON = "ADD_REQ_GEOJSON";
export const CLEAR_PV = "CLEAR_PV";
export const UPDATE_OVERLAY = "UPDATE_OVERLAY";
export const SET_OVERLAYS = "SET_OVERLAYS";
export const SET_MAIN_TAB_INDEX = "SET_MAIN_TAB_INDEX";
export const SET_SUB_TAB_INDEX = "SET_SUB_TAB_INDEX";
export const SET_TABS = "SET_TABS";
export const OPEN_SETTING_DIALOG = "OPEN_SETTING_DIALOG";
export const CLOSE_SETTING_DIALOG = "CLOSE_SETTING_DIALOG";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SET_PV_GET_DATA_ERROR = "SET_PV_GET_DATA_ERROR";
export const SET_ACTIVE_CONTROLLER = "SET_ACTIVE_CONTROLLER";

//Home projects
export const CLEAR_HOME_PROJECTS = "CLEAR_HOME_PROJECTS";
export const SET_HOME_PROJECTS = "SET_HOME_PROJECTS";

//Users
export const SET_USERS = "SET_USERS";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USER_REQUEST_STATUS = "SET_USER_REQUEST_STATUS";

//Messages
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//Images
export const SET_IMAGES = "SET_IMAGES";
export const ADD_IMAGE = "ADD_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const CLEAR_IMAGES = "CLEAR_IMAGES";

//Recipes
export const SET_IFRAMES = "SET_IFRAMES";
export const ADD_NEW_IFRAME = "ADD_NEW_IFRAME";
export const UPDATE_IFRAME = "UPDATE_IFRAME";
export const DELETE_IFRAME = "DELETE_IFRAME";

//project view state
export const REQ_TAB_SCREEN_MODE = "REQ_TAB_SCREEN_MODE";
export const REQ_TAB_CONTAINER = "REQ_TAB_CONTAINER";
export const SET_TAB_SCREEN_MODE = "SET_TAB_SCREEN_MODE";
export const SET_TAB_CONTAINER = "SET_TAB_CONTAINER";
export const SET_FULL_SCREEN_BTN = "SET_FULL_SCREEN_BTN";
export const SET_ONLY_SCREEN_VIEW = "SET_ONLY_SCREEN_VIEW";
export const CLEAR_VIEW_STATE = "CLEAR_VIEW_STATE";
