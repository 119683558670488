import {
  SET_NAVBAR_HEIGHT,
  SET_TABVIEW_WIDTH,
  SET_NAVBAR_REF,
  SET_WINDOW_SIZE,
} from "../actions/types";

export const setWindowResize = (width, height) => (dispatch) => {
  dispatch({
    type: SET_WINDOW_SIZE,
    payload: { width, height },
  });
};

export const setNavbarHeight = (height) => (dispatch) => {
  dispatch({
    type: SET_NAVBAR_HEIGHT,
    payload: height,
  });
};

export const setTabViewWidth = (height) => (dispatch) => {
  dispatch({
    type: SET_TABVIEW_WIDTH,
    payload: height,
  });
};

export const setRef = (ref) => (dispatch) => {
  dispatch({
    type: SET_NAVBAR_REF,
    payload: ref,
  });
};

export const updateNavbarHeight = (ref) => (dispatch, getState) => {
  let ref = getState().page.ref;
  if (!ref) {
    return;
  }

  dispatch({
    type: SET_NAVBAR_HEIGHT,
    payload: ref.clientHeight,
  });
};

export const getWindowHeight = (asString) => (dispatch, getState) => {
  let navHeight = getState().page.navbarHeight;
  if (asString) {
    return window.innerHeight - navHeight + "px";
  }
  return window.innerHeight - navHeight;
};
