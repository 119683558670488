
import {
    CLEAR_MESSAGE,
    SET_MESSAGE
} from './types';

import {
    SUCCES,
    INFO,
    ERROR,
    WAITING
} from '../others/GlobalConst'

const timeDelay=3000;

export const setSuccesMessage=(message)=>(dispatch)=>{
    dispatch({
        type: SET_MESSAGE,
        payload:{
            message,
            type: SUCCES
        }
    })

    setTimeout(()=>dispatch(clearMessage()), timeDelay)
}

export const setErrorMessage=(message)=>(dispatch)=>{
    dispatch({
        type: SET_MESSAGE,
        payload:{
            message,
            type: ERROR
        }
    })

    setTimeout(()=>dispatch(clearMessage()), timeDelay)
}

export const setInfoMessage=(message)=>(dispatch)=>{
    dispatch({
        type: SET_MESSAGE,
        payload:{
            message,
            type: INFO
        }
    })

    setTimeout(()=>dispatch(clearMessage()), timeDelay)

}

export const setWaitMessage=(message)=>(dispatch)=>{
    dispatch({
        type: SET_MESSAGE,
        payload:{
            message,
            type: WAITING
        }
    })
}


export const clearMessage=()=>(dispatch)=>{
    dispatch({
        type: CLEAR_MESSAGE
    })
}