import {
    SET_IMAGES,
    ADD_IMAGE,
    DELETE_IMAGE,
    CLEAR_IMAGES
} from '../actions/types';

import{
    updateOrAddObjectBasedOnKey,
    removeObjectBasedOnKey
}from './reducerHelpFunctions'


const initialState={
    images:[],
}

export default function(state=initialState, action){
    switch(action.type){

        case SET_IMAGES:
            return{
                ...state,
                images:action.payload
            }

        case ADD_IMAGE:
            return {
                ...state,
                images:updateOrAddObjectBasedOnKey(state.images, 'id', action.payload)
            }

        case DELETE_IMAGE:
            return {
                ...state,
                images:removeObjectBasedOnKey(state.images, 'id', action.payload)
            }

        case CLEAR_IMAGES:
            return {
                ...initialState
            }

        default:
            return state; 
    }
}
