
import React, { Component } from 'react'
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';
import logo from '../../images/logo3.png'

//actions
import {setNavbarHeight,setRef} from '../../actions/pageAction';

//materialUI
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';


const styles=theme=>({
    root:{
        flexGrow:1,
        zIndex:theme.zIndex.drawer+1
    },
    img:{
        // position: 'relative',
        // float:'left',
        height:'52px',
        paddingLeft:'0px'
    },
    title: {
        //margin:'auto',
        paddingLeft:'20px',
        position:'relative',
        textDecoration: 'none',
        color: '#fff'
    },
    info:{
        position:'absolute',
        right:'10px',
    },
    logo:{
        position:'absolute',
        left:'10px',
    },
    appBar:{
        zIndex: theme.zIndex.drawer + 1,
    }
})

const initState={
}

class MobileNavbar extends Component {
    constructor(props){
        super(props);
        this.state=initState;
    }

    
    componentDidMount=()=>{
        this.props.setNavbarHeight(this.refs.navBar.clientHeight);
        this.props.setRef(this.refs.navBar);
		window.onresize=()=>{
		    this.props.setNavbarHeight(this.refs.navBar.clientHeight);
		}
    }

    preventDefault = (event) => event.preventDefault();

    render() {
        const {classes}=this.props;

        return (
            <div className={classes.root}>
                <AppBar 
                    position="fixed" 
                    className={classes.appBar} 
                    ref="navBar">
                    <Toolbar>
                    <div className={classes.logo}>
                        <a  href="https://www.waterwebtools.com/" ><img src={logo} className={classes.img}/></a>
                    </div>
                    <div  className={classes.info} >
                        <a  href="https://silkeborg.dk/Borger/Natur/Badning/Badevandskvalitet/Projekt-Almindsoe"  ><InfoIcon color="secondary" fontSize="large"/></a>
                    </div>
                
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    page: state.page,
})
  
export default compose(
    withStyles(styles),
    connect(mapStateToProps, {
        setNavbarHeight,
        setRef
    })
)(MobileNavbar);