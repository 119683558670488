import {
  SET_NAVBAR_HEIGHT,
  SET_NAVBAR_REF,
  SET_WINDOW_SIZE,
} from "../actions/types";

const initialState = {
  navbarHeight: 0,
  ref: undefined,
  windowSize: { width: null, height: null },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NAVBAR_HEIGHT:
      return {
        ...state,
        navbarHeight: action.payload,
      };

    case SET_NAVBAR_REF:
      return {
        ...state,
        ref: action.payload,
      };

    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.payload,
      };

    default:
      return state;
  }
}
