import {
  //Data buffers
  ADD_BUFFER_DATA,
  SET_BUFFER_STATUS,
  CLEAR_BUFFER,
  START_UPDATE_ROUTINE,
  STOP_UPDATE_ROUTINE,
  CLEAR_EXPIRED_DATA,

  //Error handlers
  SET_ERROR_MSG,
} from "./types";

import { BUFFER_UPDATE_RATE } from "../others/GlobalConst";

import axios from "axios";
import { tokenConfig } from "./tokenConfig";
import moment from "moment-timezone";
import { getErrorMsg } from "./errorActions";

import {
  NO_REQUEST,
  WAITING,
  ERROR,
  WARNING_PICKER,
  NO_WARNING,
} from "../others/GlobalConst";

/**
 * plot data
 */
export const getPlotData = (query, queryHash) => (dispatch, getState) => {
  //set buffer status
  //Removed because of this error
  //https://github.com/facebook/react/issues/18178
  dispatch({
    type: SET_BUFFER_STATUS,
    payload: { queryHash, status: WAITING },
  });

  let url = `/asap/${query.endpoint}/data/${query.modelRef}`;
  axios
    .post(url, { vars: query.post }, tokenConfig(getState))
    .then((res) => {
      let data = res.data;
      if (Array.isArray(res.data)) {
        data = data[0];
      }
      let expire = data.nextForecast === "" ? 0 : data.nextForecast;

      dispatch({
        type: ADD_BUFFER_DATA,
        payload: {
          queryHash,
          data,
          settingsSensitiv: false,
          expire,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERROR_MSG,
        payload: {
          errorId: "BUFFER_GET_PLOT",
          errorContent:
            err.response && (err.response.data || err.response.statusText),
        },
      });
      dispatch({
        type: SET_BUFFER_STATUS,
        payload: { queryHash, status: ERROR },
      });
    });
};

export const getStaticSettingsData = (query, queryHash, settings) => (
  dispatch,
  getState
) => {
  //set buffer status
  //Removed because of this error
  //https://github.com/facebook/react/issues/18178
  dispatch({
    type: SET_BUFFER_STATUS,
    payload: { queryHash, status: WAITING },
  });

  let url = `api/simulation/static/settings`;
  let newQuery = {
    ...query,
    settings,
    levels: WARNING_PICKER,
    defaultColor: NO_WARNING,
  };
  axios
    .post(url, newQuery, tokenConfig(getState))
    .then((res) => {
      let defaultExpire = moment().add(2, "minutes").unix();
      let expire = res.data.nextForecast
        ? res.data.nextForecast
        : defaultExpire;

      dispatch({
        type: ADD_BUFFER_DATA,
        payload: {
          queryHash,
          data: res.data,
          settingsSensitiv: true,
          expire,
        },
      });
    })
    .catch((err) => {
      dispatch(getErrorMsg(err));
      dispatch({
        type: SET_BUFFER_STATUS,
        payload: { queryHash, status: ERROR },
      });
    });
};

export const getDynamicColorScaleData = (query, queryHash) => (
  dispatch,
  getState
) => {
  //set buffer status
  //Removed because of this error
  //https://github.com/facebook/react/issues/18178
  dispatch({
    type: SET_BUFFER_STATUS,
    payload: { queryHash, status: WAITING },
  });

  let url = `api/simulation/dynamic/colorscale`;
  axios
    .post(url, query, tokenConfig(getState))
    .then((res) => {
      let defaultExpire = moment().add(2, "minutes").unix();
      let expire = res.data.nextForecast
        ? res.data.nextForecast
        : defaultExpire;

      dispatch({
        type: ADD_BUFFER_DATA,
        payload: {
          queryHash,
          data: res.data,
          settingsSensitiv: true,
          expire,
        },
      });
    })
    .catch((err) => {
      dispatch(getErrorMsg(err));
      dispatch({
        type: SET_BUFFER_STATUS,
        payload: { queryHash, status: ERROR },
      });
    });
};

export const getDynamicSettingsData = (query, queryHash, settings) => (
  dispatch,
  getState
) => {
  //set buffer status
  //Removed because of this error
  //https://github.com/facebook/react/issues/18178
  dispatch({
    type: SET_BUFFER_STATUS,
    payload: { queryHash, status: WAITING },
  });

  let url = `api/simulation/dynamic/settings`;
  let newQuery = {
    ...query,
    settings,
    levels: WARNING_PICKER,
    defaultColor: NO_WARNING,
  };
  axios
    .post(url, newQuery, tokenConfig(getState))
    .then((res) => {
      let defaultExpire = moment().add(2, "minutes").unix();
      let expire = res.data.nextForecast
        ? res.data.nextForecast
        : defaultExpire;

      dispatch({
        type: ADD_BUFFER_DATA,
        payload: {
          queryHash,
          data: res.data,
          settingsSensitiv: true,
          expire,
        },
      });
    })
    .catch((err) => {
      dispatch(getErrorMsg(err));
      dispatch({
        type: SET_BUFFER_STATUS,
        payload: { queryHash, status: ERROR },
      });
    });
};

export const getQueryString = (query) => (dispatch, getState) => {
  let qs = [];
  query.forEach((q) => {
    qs.push(q.value);
  });
  return qs.join("_");
};

export const getDataFromBuffer = (queryHash) => (dispatch, getState) => {
  let buffer = getState().buffer.buffer;

  let index = buffer.findIndex((x) => {
    return x.queryHash === queryHash;
  });

  if (index === -1) return null;

  return buffer[index].data;
};

export const checkBufferStatus = (queryHash) => (dispatch, getState) => {
  let buffer = getState().buffer;

  let index = buffer.bufferStatus.findIndex((x) => {
    return x.queryHash === queryHash;
  });

  if (index === -1) return NO_REQUEST;

  return buffer.bufferStatus[index].status;
};

/**
 * Buffer handlers
 */

export const clearBuffer = () => (dispatch) => {
  dispatch({
    type: CLEAR_BUFFER,
  });
};

export const startUpdateRoutine = () => (dispatch) => {
  dispatch({
    type: START_UPDATE_ROUTINE,
  });
  dispatch(updateBuffer());
};

export const stopUpdateRoutine = () => (dispatch) => {
  dispatch({
    type: STOP_UPDATE_ROUTINE,
  });
};

export const updateBuffer = () => (dispatch, getState) => {
  let { runUpdateBuffer } = getState().buffer;

  //if increment is not requested, then request
  if (!runUpdateBuffer) return;

  setTimeout(() => {
    let buffer = getState().buffer.buffer;

    let now = moment().unix();

    buffer.forEach((data) => {
      if (data.expire < now && data.expire !== 0) {
        dispatch({
          type: CLEAR_EXPIRED_DATA,
          payload: data.queryHash,
        });
      }
    });

    dispatch(updateBuffer());
  }, BUFFER_UPDATE_RATE);
};
